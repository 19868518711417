<template>
  <div>
    <Band
      background-image="/homepage-bg.jpg"
      background-overlay="dark"
      :background-opacity="50"
      size="2xl"
      :ui="{ base: 'pb-0 md:pb-0 lg:pb-0', backgroundImage: 'bottom-24', backgroundOverlay: 'bottom-24' }"
    >
      <Heading type="h1" variant="pageTitle" centered :ui="{ base: 'text-white' }">
        Supercharge your marketing &amp; sales efforts <span class="lg:block">with free co-branded content experiences</span>
      </Heading>

      <div class="mt-12 overflow-hidden rounded-lg shadow-xl">
        <div class="grid aspect-video place-items-center overflow-hidden bg-slate-50">
          <NuxtLink to="/signup">
            <img src="/content-experience-example.png" class="h-full w-full object-cover" />
          </NuxtLink>
        </div>
      </div>
    </Band>

    <Band container="full" size="2xl" :ui="{ base: 'px-0 md:px-0' }">
      <div class="px-8 md:px-10">
        <Container>
          <div class="flex items-center justify-between">
            <Heading type="h2">Choose a content experience<span class="block">to co-brand & share</span></Heading>
            <p><Button to="/signup" append-icon="long-arrow-right" size="lg">Get started</Button></p>
          </div>
        </Container>
      </div>
      <ContentExperiencesSlider endpoint="content-experiences/new" class="mt-10 lg:mt-12" />
    </Band>
  </div>
</template>

<script setup lang="ts">
useHead({
  title: "Your Content Experiences",
});
</script>
