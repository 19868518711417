import hexRgb from "hex-rgb";
import generateSwatches from "./generateSwatches";
import type { PartnerCustomisations } from "~/types";

const afterLast = (string: string, search: string) => {
  const lastIndex = string.lastIndexOf(search);
  return string.substring(lastIndex + 1);
};

const getRgbChannels = (hex: string): string => {
  const { red, green, blue } = hexRgb(hex);
  return `${red} ${green} ${blue}`;
};

const partnerCssVariables = (customisations: PartnerCustomisations) => {
  const allowedCssVariables = ["heading_color", "body_color", "supplement_color", "link_color", "link_hover_color", "brand_color"];

  const cssVariables: Record<string, string> = {};

  Object.entries(customisations).forEach(([key, value]) => {
    if (!allowedCssVariables.includes(key) || !value) {
      return;
    }

    if (["brand_color"].includes(key)) {
      Object.entries(generateSwatches(value)).map(([shade, swatch]) => {
        cssVariables[`--ag-primary-${shade}`] = getRgbChannels(swatch.hex);
      });
    } else {
      key = `--ag-${key.replace("_color", "").replaceAll("_", "-")}`;

      if (value.startsWith("#")) {
        value = getRgbChannels(value);
      }

      cssVariables[key] = value;
    }
  });

  return cssVariables;
};

export { afterLast, getRgbChannels, partnerCssVariables };
