<template>
  <div ref="el" class="ag-slider overflow-x-hidden">
    <div :class="$cn('px-8 md:px-10', ui?.inner)">
      <Container>
        <!-- <Debug :data="{ isSwiping, direction }" /> -->
        <div class="ag-slider__slides -ml-6 grid grid-flow-col transition-transform" :class="perViewClasses" :style="{ transform: `translateX(${offset}%)` }">
          <slot />
        </div>
        <div v-if="showPreviousButton || showNextButton || showRewindButton" :class="$cn('-ml-3.5 mt-6 flex justify-end', ui?.nav)">
          <Button
            v-show="showRewindButton"
            icon="backward-fast"
            icon-prefix="fas"
            variant="link"
            @click="gotoFirstSlide"
            :ui="{ base: 'text-body hover:text-gray-700' }"
          />
          <Button
            v-show="showPreviousButton"
            icon="long-arrow-left"
            icon-prefix="fas"
            variant="link"
            @click="gotoPreviousSlide"
            :disabled="!canSlidePrevious"
            :ui="{ base: 'text-body hover:text-gray-700' }"
          />
          <Button
            v-show="showNextButton"
            icon="long-arrow-right"
            icon-prefix="fas"
            variant="link"
            @click="gotoNextSlide"
            :disabled="!canSlideNext"
            :ui="{ base: 'text-body hover:text-gray-700' }"
          />
        </div>
      </Container>
    </div>
  </div>
</template>

<script setup lang="ts">
import useBreakpoints from "~/composables/useBreakpoints";
import type { ClassValue } from "clsx";

type SlidesPerView = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;

export interface SliderProps {
  breakpoints?: Partial<Record<Breakpoint, SlidesPerView>>;
  perNavigation?: 1 | "view";
  total: number;
  ui?: Partial<{
    inner: ClassValue;
    nav: ClassValue;
  }>;
}

const props = withDefaults(defineProps<SliderProps>(), {
  perNavigation: 1,
});

const currentSlide = ref(1);
const slideWidth = computed(() => 100 / slidesPerView.value);
const offset = computed(() => -(currentSlide.value - 1) * slideWidth.value);

const { breakpoints: breakpointsConfig, currentBreakpoint } = useBreakpoints();

const slidesPerView = computed(() => {
  const breakpoint = currentBreakpoint.value;

  if (props.breakpoints) {
    if (props.breakpoints[breakpoint]) {
      return props.breakpoints[breakpoint];
    } else {
      const breakpointsArray: Breakpoint[] = Object.keys(props.breakpoints).sort((a, b) => breakpointsConfig[b] - breakpointsConfig[a]);

      for (const key of breakpointsArray) {
        if (breakpointsConfig[key] < breakpointsConfig[breakpoint]) {
          return props.breakpoints[key];
        }
      }
    }
  }

  return 1;
});

// Slide Navigation
const perNavigation = computed(() => (props.perNavigation === "view" ? slidesPerView.value : props.perNavigation));
const canSlideNext = computed(() => currentSlide.value + perNavigation.value - 1 < props.total);
const canSlidePrevious = computed(() => currentSlide.value > 1);

const showNextButton = computed(() => props.total > slidesPerView.value);
const showPreviousButton = computed(() => props.total > slidesPerView.value);
const showRewindButton = computed(() => props.total > slidesPerView.value && !canSlideNext.value);

const gotoNextSlide = () => {
  if (!canSlideNext.value) return;
  currentSlide.value = currentSlide.value + perNavigation.value;
};

const gotoPreviousSlide = () => {
  if (!canSlidePrevious.value) return;
  const slideToIndex = currentSlide.value - perNavigation.value;
  currentSlide.value = slideToIndex < 1 ? 1 : slideToIndex;
};

const gotoFirstSlide = () => (currentSlide.value = 1);

// Touch support
const el = ref();

const { isSwiping, direction } = useSwipe(el, {
  onSwipeEnd(e, direction) {
    //console.log("Swipe End", e, direction);
  },
});

// Classes
const perViewClasses = computed(() => {
  if (!props.breakpoints) return;

  let classes: string[] = [];

  Object.entries(props.breakpoints).forEach(([key, value]) => {
    classes.push(`${key}:${value}-slides`);
  });

  return classes;
});
</script>

<style lang="postcss">
.ag-slider {
  &__slides {
    grid-auto-columns: 100%;

    > * {
      @apply ml-6;
    }

    @media screen(sm) {
      &.sm\:1-slides {
        grid-auto-columns: 100%;
      }

      &.sm\:2-slides {
        grid-auto-columns: 50%;
      }

      &.sm\:3-slides {
        grid-auto-columns: 33.333333%;
      }

      &.sm\:4-slides {
        grid-auto-columns: 25%;
      }

      &.sm\:5-slides {
        grid-auto-columns: 20%;
      }

      &.sm\:6-slides {
        grid-auto-columns: 16.666667%;
      }

      &.sm\:7-slides {
        grid-auto-columns: 14.285715%;
      }

      &.sm\:8-slides {
        grid-auto-columns: 12.5%;
      }
    }

    @media screen(md) {
      &.md\:1-slides {
        grid-auto-columns: 100%;
      }

      &.md\:2-slides {
        grid-auto-columns: 50%;
      }

      &.md\:3-slides {
        grid-auto-columns: 33.333333%;
      }

      &.md\:4-slides {
        grid-auto-columns: 25%;
      }

      &.md\:5-slides {
        grid-auto-columns: 20%;
      }

      &.md\:6-slides {
        grid-auto-columns: 16.666667%;
      }

      &.md\:7-slides {
        grid-auto-columns: 14.285715%;
      }

      &.md\:8-slides {
        grid-auto-columns: 12.5%;
      }
    }

    @media screen(lg) {
      &.lg\:1-slides {
        grid-auto-columns: 100%;
      }

      &.lg\:2-slides {
        grid-auto-columns: 50%;
      }

      &.lg\:3-slides {
        grid-auto-columns: 33.333333%;
      }

      &.lg\:4-slides {
        grid-auto-columns: 25%;
      }

      &.lg\:5-slides {
        grid-auto-columns: 20%;
      }

      &.lg\:6-slides {
        grid-auto-columns: 16.666667%;
      }

      &.lg\:7-slides {
        grid-auto-columns: 14.285715%;
      }

      &.lg\:8-slides {
        grid-auto-columns: 12.5%;
      }
    }

    @media screen(xl) {
      &.xl\:1-slides {
        grid-auto-columns: 100%;
      }

      &.xl\:2-slides {
        grid-auto-columns: 50%;
      }

      &.xl\:3-slides {
        grid-auto-columns: 33.333333%;
      }

      &.xl\:4-slides {
        grid-auto-columns: 25%;
      }

      &.xl\:5-slides {
        grid-auto-columns: 20%;
      }

      &.xl\:6-slides {
        grid-auto-columns: 16.666667%;
      }

      &.xl\:7-slides {
        grid-auto-columns: 14.285715%;
      }

      &.xl\:8-slides {
        grid-auto-columns: 12.5%;
      }
    }
  }
}
</style>
