import { convertHexToHsl, convertHexToRgb, convertHslToHex, convertHslToRgb } from "./colors";

type ColorSwatch = {
  hex: string;
  hue: number;
  saturation: number;
  lightness: number;
  distribution: number;
};

type ColorSwatches = {
  50: ColorSwatch;
  100: ColorSwatch;
  200: ColorSwatch;
  300: ColorSwatch;
  400: ColorSwatch;
  500: ColorSwatch;
  600: ColorSwatch;
  700: ColorSwatch;
  800: ColorSwatch;
  900: ColorSwatch;
  950: ColorSwatch;
};

type CreateSwatchesType = (
  hexColor: string,
  useLightness?: boolean,
  baseStop?: number,
  hue?: number,
  saturation?: number,
  minLightness?: number,
  maxLightness?: number
) => ColorSwatches;

const STOPS = [0, 50, 100, 200, 300, 400, 500, 600, 700, 800, 900, 950, 1000];

/**
 * Generates color swatches.
 */
const generateSwatches: CreateSwatchesType = (
  hexColor: string,
  useLightness: boolean = false,
  baseStop: number = 500,
  hue: number = 0,
  saturation: number = 0,
  minLightness: number = 0,
  maxLightness: number = 100
): ColorSwatches => {
  // Create hue and saturation scales based on tweaks
  const hueScale = createHueScale(hue, baseStop);
  const saturationScale = createSaturationScale(saturation, baseStop);

  // Get the base H/S/L values
  const hsl = convertHexToHsl(hexColor);
  const baseHue = hsl.hue;
  const baseSaturation = hsl.saturation;
  const baseLightness = hsl.lightness;

  // Create lightness scales based on tweak + lightness/luminance of current value
  const lightnessValue = useLightness ? baseLightness : calculateLuminanceFromHex(hexColor);
  const distributionScale = createDistributionValues(lightnessValue, minLightness, maxLightness, baseStop);

  let swatches = {};

  STOPS.forEach((stop: number, index: number) => {
    if (stop === 0 || stop === 1000) {
      return;
    }

    let newHue = Math.round(baseHue + hueScale[stop]);
    let newSaturation = Math.round(clamp(baseSaturation + saturationScale[stop]));
    let newLightness = useLightness ? distributionScale[stop] : calculateLightnessFromLuminance(newHue, newSaturation, distributionScale[stop]);
    let newHex = stop === baseStop ? hexColor : convertHslToHex(newHue, newSaturation, newLightness);

    swatches[stop] = {
      hex: newHex,
      hue: newHue,
      saturation: newSaturation,
      lightness: newLightness,
      distribution: distributionScale[stop],
    };
  });

  return swatches;
};

/**
 * Creates a scale for hue adjustments.
 */
const createHueScale: (hue: number, baseStop: number) => Record<number, number> = (hue: number, baseStop: number) =>
  createScale(hue, baseStop, (diff, hue) => diff * hue);

/**
 * Creates a scale for saturation adjustments.
 */
const createSaturationScale = (saturation: number, baseStop: number): Record<number, number> =>
  createScale(saturation, baseStop, (diff, saturation) => Math.min(100, Math.round((diff + 1) * saturation * (1 + diff / 10))));

/**
 * Generic function to create a scale for adjustments.
 */
const createScale = (adjustment: number, baseStop: number, calculation: (diff: number, adjustment: number) => number): Record<number, number> => {
  const index: number = STOPS.indexOf(baseStop);

  const result: Record<number, number> = {};

  STOPS.forEach((stop, i) => {
    const diff: number = Math.abs(i - index);
    result[stop] = adjustment ? calculation(diff, adjustment) : 0;
  });

  return result;
};

/**
 * Creates distribution values for lightness.
 */
const createDistributionValues = (lightness: number, min: number, max: number, baseStop: number): Record<number, number> => {
  const stops = STOPS;

  let flippedStops: Record<number, number> = {};

  // Flip the stops for later calculations
  stops.forEach((stop: number, index: number) => {
    flippedStops[stop] = index;
  });

  let distribution: Record<number, number> = {
    0: Math.round(max),
    [baseStop]: Math.round(lightness),
    1000: Math.round(min),
  };

  stops.forEach((stop: number) => {
    if (distribution[stop] !== undefined) {
      return;
    }

    let diff = Math.abs((stop - baseStop) / 100);

    let totalDiff = stop < baseStop ? Math.abs(flippedStops[baseStop] - flippedStops[0]) - 1 : Math.abs(flippedStops[baseStop] - flippedStops[1000]) - 1;

    let increment = stop < baseStop ? max - lightness : lightness - min;

    let tweak = stop < baseStop ? (increment / totalDiff) * diff + lightness : lightness - (increment / totalDiff) * diff;

    distribution[stop] = Math.round(tweak);
  });

  // Sort in descending order by stop
  distribution = Object.keys(distribution)
    .sort((a, b) => distribution[Number(b)] - distribution[Number(a)])
    .reduce((obj: Record<number, number>, key: string) => {
      return {
        ...obj,
        [Number(key)]: distribution[Number(key)],
      };
    }, {});

  return distribution;
};

/**
 * Calculates the luminance from a hex color.
 */
const calculateLuminanceFromHex = (hex: string) => {
  const rgb = convertHexToRgb(hex);

  return calculateLuminanceFromRgb(rgb.red, rgb.green, rgb.blue);
};

/**
 * Calculates the luminance from RGB values.
 */
const calculateLuminanceFromRgb = (red: number, green: number, blue: number): number => {
  // Formula from WCAG 2.0
  let rgb: number[] = [red, green, blue];

  let converted: number[] = rgb.map(function (c: number) {
    c /= 255; // to 0-1 range
    return c < 0.03928 ? c / 12.92 : Math.pow((c + 0.055) / 1.055, 2.4);
  });

  const [$R, $G, $B] = converted;

  return 21.26 * $R + 71.52 * $G + 7.22 * $B;
};

/**
 * Calculates the lightness from HSL and luminance values.
 */
const calculateLightnessFromLuminance = (H: number, S: number, Lum: number): number => {
  let vals: Record<number, number> = {};
  for (let L = 99; L >= 0; L--) {
    let rgb = convertHslToRgb(H, S, L);
    vals[L] = Math.abs(Lum - calculateLuminanceFromRgb(rgb.red, rgb.green, rgb.blue));
  }

  // Run through all these and find the lowest diff
  let lowestDiff = 100;
  let newL = 100;

  for (const L in vals) {
    if (vals[L] < lowestDiff) {
      newL = Number(L);
      lowestDiff = vals[L];
    }
  }

  return newL;
};

/**
 * Clamps a value between 0 and 100.
 */
const clamp = ($number: number): number => Math.max(0, Math.min(100, $number));

export default generateSwatches;
